import React, { useEffect } from "react"
import * as styles from "./signUp.module.css"
import Logo from "components/tools/logo.js"
import { navigate } from "gatsby-link"
const SignUp = props => {
  const name = props?.location?.state?.name

  useEffect(() => {
    if (!name) navigate(-1)
  }, [name])

  return (
    <div className={styles.con}>
      <div className={styles.logoCon}>
        <Logo w={206.36} h={46} white={true} navCancel={false} />
      </div>
      <div>
        <div className={`colContainer ${styles.welcome}`}>
          <div className={styles.main}>
            {name}님, 회원가입을 <br />
            환영합니다
            <div className={styles.dot}>
              <div>.</div>
              <div>.</div>
            </div>
          </div>
          <div
            className={styles.loginBox}
            onClick={() => navigate("/member/profile/registerProfile/")}
            style={{ fontSize: "16px" }}
          >
            프로필 생성하기
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp
